
import MuiAppBar from '@material-ui/core/AppBar';
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Toolbar, { styles as toolbarStyles } from './Toolbar';

const styles = theme => ({
  root: {
    color: theme.palette.common.white,
  },
  title: {
    fontSize: 24,
  },
  placeholder: toolbarStyles(theme).root,
  toolbar: {
    justifyContent: 'space-around',
  },
  left: {
    flex: 1,
  },
  leftLinkActive: {
    color: theme.palette.common.white,
  },
  right: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  rightLink: {
    fontSize: 16,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(3),
  },
  linkSecondary: {
    color: theme.palette.secondary.main,
  },
});

function AppBar(props) {
  const { classes } = props;

  return (
    <div>
      <MuiAppBar position="fixed" elevation={0}>
        <Toolbar className={classes.toolbar}>
          <Link variant="h6" underline="none" color="inherit" className={classes.title} href="/">
            {'CheckRoof'}
          </Link>
        </Toolbar>
      </MuiAppBar>
    <div className={classes.placeholder} />
  </div>
);
}

AppBar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppBar);