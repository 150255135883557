import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import Autocomplete from 'react-google-autocomplete';
import { GoogleMapsAPIKey, getMethodEncryptKey, } from '../constants';
import { AES, enc } from 'crypto-js';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  autocomplete: {
    flex: 1,
    width: '100%',
    height: '3em',
    border: 'none',
    padding: 10,
  },
}));

const validateSrch = (place) => {
  if (!place) {
    alert("Please enter a valid location in the search place!");
    return;
  }
  const lat = place.geometry.location.lat(), lng = place.geometry.location.lng(); // put in try-catch format
  const location = AES.encrypt(JSON.stringify({...place, lat, lng}), getMethodEncryptKey).toString();
  window.open(`selectarea/?location=${encodeURIComponent(location)}&key=${encodeURIComponent(getMethodEncryptKey)}`, "_self");
}

export default function SearchInputBox(props) {
  const classes = useStyles();
  let srchPlace = null;

  return (
    <Paper component="form" className={classes.root}>
      <Autocomplete id="autoComplete"
        apiKey={GoogleMapsAPIKey}
        libraries= {["places"]}
        style={{width: '100%', height: '3em', border: 'none', padding: 10, flex: 1}}
        onPlaceSelected={(place) => {srchPlace = place;}}
        types={['geocode']}
        // componentRestrictions={{ country: "in", }}
      />

      <IconButton
        onClick={(event) => validateSrch(srchPlace)}
        className={classes.iconButton}
        aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}
