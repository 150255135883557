/* eslint-disable no-undef */
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
} from "react-google-maps";
import { DrawingManager } from "react-google-maps/lib/components/drawing/DrawingManager";
import React, { Component }  from 'react';
import { GoogleMapsAPIKey, }  from '../../lib/constants';

// Try to keep all stuff related to Google Maps in this component (for this page).
// Otherwise transfer the loading of API in the parent component - no need to load twice for same page.

const MapWithADrawingManager = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${GoogleMapsAPIKey}&v=3.exp&libraries=drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)(props => {
  const { lat, lng, } = props.defaultCoords;
  return (
    <GoogleMap defaultZoom={18} defaultCenter={new google.maps.LatLng(lat || 28.7041, lng || 77.1025)}>
      <DrawingManager
        defaultDrawingMode={google.maps.drawing.OverlayType.POLYGON}
        defaultOptions={{
          drawingControl: true,
          drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [
              google.maps.drawing.OverlayType.POLYGON,
            ],
          },
          circleOptions: {
            fillColor: `#ffff00`,
            fillOpacity: 1,
            strokeWeight: 5,
            clickable: false,
            editable: true,
            zIndex: 1,
          },
        }}
        onPolygonComplete={roofObj => {
          const area = google.maps.geometry.spherical.computeArea(roofObj.getPath());
          if (area) {
            props.onPolygonComplete({ // extended - relevant info useful to parent
              lat: roofObj.getPath().getAt(0).lat(), // this is reliable for server (backend)
              lng: roofObj.getPath().getAt(0).lng(), // this is reliable for server (backend)
              area
            });
          }
        }}
      />
    </GoogleMap>)
  }
);

export default MapWithADrawingManager;
