export const GoogleMapsAPIKey = "AIzaSyDZ39OBhY6EGGIvUFYSg6kk0UxZEgDpFBo";

export const getMethodEncryptKey = "Secret Key 123"; // can obtain every new session too!

export const copyRightYear = "2020";

export const SuppLangs = [
  { code: 'en-US', name: 'English', },
  { code: 'hi-HI', name: 'हिन्दी', },
  { code: 'fr-FR', name: 'Français', },
];

export const mediaHandles = {
  web: "https://checkroof.org/",
  fb: "https://facebook.com/", // add appropriate handle name
  twitter: "https://twitter.com/", // add appropriate handle name
}

export const MONTH_SHORT = [
  'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
  'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
];

export const SERVER_URL = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? 'http://localhost:8000/' : 'https://some-server.com/';
