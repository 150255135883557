import withRoot from '../lib/withRoot';
// --- Post bootstrap -----
import React from 'react';
import ProductCategories from './components/ProductCategories';
import ProductSmokingHero from './components/ProductSmokingHero';
import AppFooter from '../lib/components/AppFooter';
import ProductHero from './components/ProductHero';
import ProductValues from './components/ProductValues';
import ProductHowItWorks from './components/ProductHowItWorks';
import ProductCTA from './components/ProductCTA';
import AppBar from '../lib/components/appBarComponent';
import DemoVideo from './components/DemoVideo';
import Recognitions from './components/Recognitions';

function Index() {
  return (
    <React.Fragment>
      <AppBar />
      <ProductHero />
      <ProductHowItWorks />
      <Recognitions />
      {/* <ProductCTA />
      <ProductSmokingHero /> */}
      <DemoVideo/>
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(Index);