import withRoot from '../../lib/withRoot';
import * as React from 'react';
import { Button, Grid, Paper } from '@material-ui/core';
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  LineSeries,
  Title,
  Legend,
  AreaSeries,
  BarSeries
} from '@devexpress/dx-react-chart-material-ui';

import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import { ArgumentScale, Animation, Stack } from '@devexpress/dx-react-chart';
import {
  curveCatmullRom,
  line,
} from 'd3-shape';
import { scalePoint } from 'd3-scale';


import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';


import waterdrop from '../../assets/waterdrop.png';
import watertanker from '../../assets/watertanker.png';
import groundwater from '../../assets/groundwater.png';
import well from '../../assets/well.png';
// import personaliseAnalysisImage from '../../assets/personalise-analysis.png';
// import selectInstallationImage from '../../assets/select-installation.png';

const titleStyles = {
  title: {
    textAlign: 'center',
    width: '100%',
    marginBottom: '10px',
  },
};
const Text = withStyles(titleStyles)((props) => {
  const { text, classes } = props;
  const [mainText, subText] = text.split('\\n');
  return (
    <div className={classes.title}>
      <Typography component="h3" variant="h5">
        {mainText}
      </Typography>
      <Typography variant="subtitle1">{subText}</Typography>
    </div>
  );
});

const legendStyles = (theme) => ({
  root: {
    display: 'flex',
    margin: 'auto',
    flexDirection: 'row',
  },
});

const useStyles = (theme) => ({

  map: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  dividerFullWidth: {
    margin: `5px 0 0 ${theme.spacing(2)}px`,
  },
  number: {
    align: 'center',
    fontSize: 48,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  impactImage: {
    height: 150,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  impactEqual: {
    marginTop: theme.spacing(10),
  },
  impactNumber: {
    align: 'center',
    fontSize: 30,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
  }
});

const legendRootBase = ({ classes, ...restProps }) => (
  <Legend.Root {...restProps} className={classes.root} />
);
const Root = withStyles(legendStyles, { name: 'LegendRoot' })(legendRootBase);
const legendLabelStyles = () => ({
  label: {
    whiteSpace: 'nowrap',
  },
});
const legendLabelBase = ({ classes, ...restProps }) => (
  <Legend.Label className={classes.label} {...restProps} />
);
const Label = withStyles(legendLabelStyles, { name: 'LegendLabel' })(legendLabelBase);



class Saving extends React.PureComponent {

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>

        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Paper className={classes.paper}>

              <Typography variant="h5" component="h2">
                ANNUAL WATER PRODUCTION CAPACITY
                  </Typography>
              <Divider />
              <br></br>
              <Typography variant="body2" component="p">
                Based on your historical rainfall, climate and geography a recommended rainwater harvesting installation can this much water annual for you.
                  </Typography>
              <br></br>
              <Typography variant="h3" className={classes.number}>
                {parseInt(this.props.raindata["annualData"]["supply"])} Liter
                  </Typography>
              <Button size="small">Learn More</Button>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.paper}>

              <Typography variant="h5" component="h2">
                CONSUMED RAINWATER
              </Typography>
              <Divider />
              <br></br>
              <Typography variant="body2" component="p">
                Based on your usage pattern, provisioned capacity and consumption predicted rainwater that you can consume
              </Typography>
              <br></br>
              <Typography variant="h3" className={classes.number}>
                {parseInt(this.props.raindata["annualData"]["savedConsumption"])} Liter
                  </Typography>
              <Button size="small">Learn More</Button>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.paper}>
              <Typography variant="h5" component="h2">
                ANNUAL SAVING
                  </Typography>
              <Divider />
              <br></br>
              <Typography variant="body2" component="p">
                Based on your city and water prices we estimate the money saving if you adopt rainwater harvesting.
                  </Typography>
              <br></br>
              <Typography variant="h3" className={classes.number}>
                {parseInt(this.props.raindata["annualData"]["savedConsumption"] * 0.30)} ₹
                  </Typography>
              <Button size="small">Learn More</Button>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper className={classes.map}>
              <Chart
                data={this.props.raindata["RWHData"]}
                className={classes.chart}
              >

                <ArgumentAxis />
                <ValueAxis />

                <BarSeries
                  name="Demand"
                  valueField="demand"
                  argumentField="month"
                  color="#e62958"
                />
                <BarSeries
                  name="Supply"
                  valueField="supply"
                  argumentField="month"
                  color="#1C76D2"
                />

                <Legend position="bottom" rootComponent={Root} labelComponent={Label} />
                <Title
                  text="Your rainwater harvesting impact\n(Water conservation in liters)"
                  textComponent={Text}
                />
                <Animation />
                <Stack />
              </Chart>
            </Paper>

          </Grid>
          <Grid item xs={6}>
            <Paper className={classes.map}>
              <Chart
                data={this.props.raindata["RWHData"]}
                className={classes.chart}
              >

                <ArgumentAxis />
                <ValueAxis />

                <BarSeries
                  name="Required supplemental water"
                  valueField="supplementalWater"
                  argumentField="month"
                  color="#e62958"
                />
                <BarSeries
                  name="Groundwater recharge"
                  valueField="recharge"
                  argumentField="month"
                  color="#1C76D2"
                />
                <Legend position="bottom" rootComponent={Root} labelComponent={Label} />
                <Title
                  text="Your rainwater harvesting impact\n(Water conservation in liters)"
                  textComponent={Text}
                />
                <Animation />
                <Stack />
              </Chart>
            </Paper>

          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={8}>
            <Card>
              <Paper className={classes.paper}>
                <Typography variant="h5" component="h2" >
                  Your potential environmental impact
                  </Typography>
                <Divider />
                <br></br>
                <Typography variant="body2" component="p">Estimated annual environmental impact of the recommended rainwater harvesting installation.</Typography>
                <div>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={3}>
                      <div className={classes.item}>
                        <img
                          src={waterdrop}
                          alt="find house"
                          className={classes.impactImage}
                        />
                        <div className={classes.impactNumber}>{parseInt(this.props.raindata["annualData"]["recharge"])} Liters</div>
                        <Typography variant="h5" align="center">
                          Groundwater recharged
                </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={1} className={classes.impactEqual}>
                      <Typography variant="h2" align="center" >=</Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <div className={classes.item}>
                        <img
                          src={watertanker}
                          alt="personalise analysis"
                          className={classes.impactImage}
                        />
                        <div className={classes.impactNumber}>{parseInt(this.props.raindata["annualData"]["supply"] / 3000)} Tankers</div>
                        <Typography variant="h5" align="center">
                          Taken off the road
                </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={1} className={classes.impactEqual}>
                      <Typography variant="h2" align="center" >=</Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <div className={classes.item}>
                        <img
                          src={well}
                          alt="select installation"
                          className={classes.impactImage}
                        />
                        <div className={classes.impactNumber}>{parseInt(this.props.raindata["annualData"]["recharge"]/800)} Times</div>
                        <Typography variant="h5" align="center">
                          Shallow well recharged
                </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Paper>

            </Card>

          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
      </div>
    );
  }
}

export default withRoot(withStyles(useStyles)(Saving));
