import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "../../lib/components/Typography";

import airbusmage from "../../assets/recognitions/airbus.png";
import nitiImage from "../../assets/recognitions/niti.png";
import cieImage from "../../assets/recognitions/cie.png";
import aimImage from "../../assets/recognitions/aim.png";
import googleImage from "../../assets/recognitions/google.png";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(9),
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(15),
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 55,
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  curvyLines: {
    pointerEvents: "none",
    position: "absolute",
    top: -180,
  },
});

function Recognitions(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <Typography
          variant="h4"
          marked="center"
          className={classes.title}
          component="h2"
        >
          Recognitions
        </Typography>
        <Grid container spacing={5}>
          <Grid item xs={12} md={3}>
            <div className={classes.item}>
              <img className={classes.image} src={airbusmage} alt="suitcase" />
              <Typography variant="h6" className={classes.title}>
                Airbus D&S,France
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            <div className={classes.item}>
              <img className={classes.image} src={googleImage} alt="graph" />
              <Typography variant="h6" className={classes.title}>
                Google for startups
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            <div className={classes.item}>
              <img className={classes.image} src={cieImage} alt="clock" />
              <Typography variant="h6" className={classes.title}>
                AIM incubation, Hyd
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            <div className={classes.item}>
              <img className={classes.image} src={aimImage} alt="clock" />
              <Typography variant="h6" className={classes.title}>
                IIIT Hyderabad
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

Recognitions.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Recognitions);
