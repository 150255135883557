import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '../../lib/components/Button';
import Typography from '../../lib/components/Typography';
import ProductHeroLayout from './ProductHeroLayout';
import TextField from '@material-ui/core/TextField';
import SearchBox from '../../lib/components/SearchBox';


import backgroundImage from '../../assets/pilani.png';

const styles = theme => ({
    background: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: '#7fc7d9', // Average color of the background image.
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    },
    button: {
        minWidth: 200,
    },
    h5: {
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(4),
        [theme.breakpoints.up('sm')]: {
            marginTop: theme.spacing(10),
        },
    },
    more: {
        marginTop: theme.spacing(2),
    }
});

function ProductHero(props) {
    const { classes } = props;

    return (
        <ProductHeroLayout backgroundClassName={classes.background}>
            {/* Increase the network loading priority of the background image. */}
            {/* <img style={{ display: 'none' }} src={backgroundImage} alt="increase priority" />
      `      <Typography color="inherit" align="center" variant="h2" marked="center">
                Upgrade your Sundays
            </Typography>
            <Typography color="inherit" align="center" variant="h5" className={classes.h5}>
                Enjoy secret offers up to -70% off the best luxury hotels every Sunday.
            </Typography>
            <Button
                color="secondary"
                variant="contained"
                size="large"
                className={classes.button}
                component="a"
                href="/premium-themes/onepirate/sign-up/"
            >
                Register
            </Button>
            <Typography variant="body2" color="inherit" className={classes.more}>
                Discover the experience
            </Typography> */}
            <Typography color="inherit" align="center" variant="h2" marked="center">
                Check Roof
            </Typography>
            <Typography color="inherit" align="center" variant="h5" className={classes.h5}>
                Discover your water and energy savings potential.
            </Typography>
            <SearchBox id="address" label="Enter your address" />
            <Typography variant="body2" color="inherit" className={classes.more}>
            See what’s possible with rooftop of your house.
            </Typography>
        </ProductHeroLayout>
    );
}

ProductHero.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHero);



// handleLoadScript = () => {
//     const googleMapsScriptUrl = `https://maps.googleapis.com/maps/api/js?key=${this.props.apiKey}&libraries=places`;

//     // Check if script exists already
//     if (
//       document.querySelectorAll(`script[src="${googleMapsScriptUrl}"]`).length >
//       0
//     ) {
//       return Promise.resolve();
//     }

//     this.googleMapsScript = document.createElement('script');
//     this.googleMapsScript.src = googleMapsScriptUrl;

//     document.body.appendChild(this.googleMapsScript);

//     return new Promise((resolve) => {
//       this.googleMapsScript.addEventListener('load', () => resolve());
//     });
//   };