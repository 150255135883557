import React from 'react';
import {
  Container,
  Grid,
  Icon,
  Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Facebook, Twitter, } from '@material-ui/icons';
import { copyRightYear, SuppLangs, mediaHandles, } from '../constants';
import Typography from './Typography';
import TextField from './TextField';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    backgroundColor: '#fed8b1',
  },
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: 'flex',
  },
  iconsWrapper: {
    height: 120,
  },
  icons: {
    display: 'flex',
  },
  icon: {
    width: 48,
    height: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  list: {
    margin: 0,
    listStyle: 'none',
    padding: 0,
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  language: {
    marginTop: theme.spacing(1),
    width: 150,
  },
}));

export default function AppFooter() {
  const classes = useStyles();
  return (
    <Typography component="footer" className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={5}>
          <Grid item xs={6} sm={4} md={3}>
            <Grid container direction="column" justify="flex-end" className={classes.iconsWrapper} spacing={2}>
              <Grid item className={classes.icons}>
                <Link href={mediaHandles.fb} title="Like on Facebook" target="_blank">
                  <Facebook fontSize="large" className={classes.icon} style={{color: '#4267B2'}} />
                </Link>
                &emsp;
                <Link href={mediaHandles.twitter} title="Follow on Twitter" target="_blank">
                  <Twitter fontSize="large" className={classes.icon} style={{color: '#1DA1F2'}} />
                </Link>
              </Grid>
              <Grid item>
                ©&nbsp;
                <Link color="inherit" href="https://checkroof.org/">CheckRoof</Link>
                {` ${copyRightYear}`}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Typography variant="h6" marked="left" gutterBottom>
              Legal
            </Typography>
            <ul className={classes.list}>
              <li className={classes.listItem}><Link href="/terms/">Terms</Link></li>
              <li className={classes.listItem}><Link href="/privacy/">Privacy</Link></li>
            </ul>
          </Grid>
          <Grid item xs={6} sm={8} md={4}>
            <Typography variant="h6" marked="left" gutterBottom>Language</Typography>
            <TextField select SelectProps={{ native: true, }} className={classes.language}>
              {SuppLangs.map(language => (
                <option value={language.code} key={language.code}>{language.name}</option>
              ))}
            </TextField>
          </Grid>
          <Grid item>
            <Typography variant="caption">
              {'This template made by '}
              <Link href="https://www.freepik.com" rel="sponsored" title="Freepik" target="_blank">
                Freepik
              </Link>
              {' from '}
              <Link href="https://www.flaticon.com" rel="sponsored" title="Flaticon" target="_blank">
                FlatIcon
              </Link>
              {' is licensed by '}
              <Link
                href="https://creativecommons.org/licenses/by/3.0/"
                rel="noopener noreferrer" title="Creative Commons BY 3.0" target="_blank">
                CC BY 3.0
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}
