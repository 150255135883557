import withRoot from '../lib/withRoot';
import React from 'react';
import AppFooter from '../lib/components/AppFooter';
import AppBar from '../lib/components/appBarComponent';
import { withStyles } from '@material-ui/core/styles';
import { Button, Grid, Paper } from '@material-ui/core';
import DrawableMap from './components/DrawableMap';
import { AES, enc, } from 'crypto-js';
import { withScriptjs, withGoogleMap, GoogleMap, } from "react-google-maps";
import { Facebook, Twitter, Close, } from '@material-ui/icons';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import TextField from '../lib/components/TextField'
import axios from "axios";
import Saving from './components/Saving';

const raindataHard = {
  "RWHData": [
      {
          "month": "January",
          "demand": 6600.0,
          "supply": 2176.4671666912486,
          "savedConsumption": 2176.4671666912486,
          "tankVolume": 0,
          "recharge": 0,
          "supplementalWater": 4423.532833308751
      },
      {
          "month": "February",
          "demand": 6600.0,
          "supply": 2808.344731214514,
          "savedConsumption": 2808.344731214514,
          "tankVolume": 0,
          "recharge": 0,
          "supplementalWater": 3791.655268785486
      },
      {
          "month": "March",
          "demand": 6600.0,
          "supply": 5195.437752746851,
          "savedConsumption": 5195.437752746851,
          "tankVolume": 0,
          "recharge": 0,
          "supplementalWater": 1404.5622472531486
      },
      {
          "month": "April",
          "demand": 6600.0,
          "supply": 14814.018457156562,
          "savedConsumption": 6600.0,
          "tankVolume": 2000.0,
          "recharge": 6214.018457156562,
          "supplementalWater": 0
      },
      {
          "month": "May",
          "demand": 6600.0,
          "supply": 24292.18192500555,
          "savedConsumption": 6600.0,
          "tankVolume": 2000.0,
          "recharge": 17692.18192500555,
          "supplementalWater": 0
      },
      {
          "month": "June",
          "demand": 6600.0,
          "supply": 76808.22839871696,
          "savedConsumption": 6600.0,
          "tankVolume": 2000.0,
          "recharge": 70208.22839871696,
          "supplementalWater": 0
      },
      {
          "month": "July",
          "demand": 6600.0,
          "supply": 133817.62644237158,
          "savedConsumption": 6600.0,
          "tankVolume": 2000.0,
          "recharge": 127217.62644237158,
          "supplementalWater": 0
      },
      {
          "month": "August",
          "demand": 6600.0,
          "supply": 123918.21126484043,
          "savedConsumption": 6600.0,
          "tankVolume": 2000.0,
          "recharge": 117318.21126484043,
          "supplementalWater": 0
      },
      {
          "month": "September",
          "demand": 6600.0,
          "supply": 124409.67159280296,
          "savedConsumption": 6600.0,
          "tankVolume": 2000.0,
          "recharge": 117809.67159280296,
          "supplementalWater": 0
      },
      {
          "month": "October",
          "demand": 6600.0,
          "supply": 66347.1442749429,
          "savedConsumption": 6600.0,
          "tankVolume": 2000.0,
          "recharge": 59747.1442749429,
          "supplementalWater": 0
      },
      {
          "month": "November",
          "demand": 6600.0,
          "supply": 13409.846091549305,
          "savedConsumption": 6600.0,
          "tankVolume": 2000.0,
          "recharge": 6809.846091549305,
          "supplementalWater": 0
      },
      {
          "month": "December",
          "demand": 6600.0,
          "supply": 3018.9705860556023,
          "savedConsumption": 3018.9705860556023,
          "tankVolume": 0,
          "recharge": 0,
          "supplementalWater": 3581.0294139443977
      }
  ],
  "annualData": {
      "month": "Annual",
      "demand": 79200.0,
      "supply": 591016.1486840945,
      "savedConsumption": 65999.22023670822,
      "tankVolume": 16000.0,
      "recharge": 523016.9284473863,
      "supplementalWater": 13200.779763291783
  }
}

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(5)
  },
  location: {
    fontSize: "1.2em",
    fontWeight: "bold",
    padding: theme.spacing(2),
    color: theme.palette.text.primary,
  },
  list: {
    fontSize: "1.2em",
    padding: "1.3em",
    paddingTop: "0.1em",
    color: theme.palette.text.primary,
  },
  customInput: {
    marginTop: 20,
    paddingLeft: "1em",
    paddingRight: "1em"
  },
  customInputField: {
    paddingRight: "2em",
  },
  analyzeBtn: {
    marginTop: 30,
    backgroundColor: "#76ba1b",
    color: "white",
    borderRadius: "1.5em",
    "&:hover": {
      backgroundColor: "#4c9a2a",
    }
  },
  scroll: {
    overflowY: 'scroll',
    height: 'fit-content',
    maxHeight: '280px',
  }
});

const DropdownTransform = {
  fontSize: 'small',
  padding: 0,
  transitionDuration: '0.3s',
  transitionProperty: 'transform',
};

class MapResults extends React.Component {
  constructor(props) {
    super(props);
    let encLocation = "", getMethodKey = "", location = {};
    try {
      const URLParams = new URLSearchParams(window.location.search);
      location = JSON.parse(AES.decrypt(URLParams.get("location") || '', URLParams.get("key") || '').toString(enc.Utf8));
    } catch (err) {
      // console.log(err);
      location = {};
    }
    this.state = { ...location, roofList: [], raindata:[], noOfResidents:4, waterStorageCapacity: 2000};
    // lat, lng, address_components, place_id, etc. - all unreliable for backend, but mostly useful
  }

  addRoof = (roof) => {
    console.log(roof);
    // object with { lat, lng, area, }
    const { roofList, } = this.state;
    this.setState({
      roofList: [...roofList, {
        ...roof, tilt: 0, azimuth: 0, // basic details
        isOpenAdvOptions: false,
        // wattage: CONFIG.defaultWattagePerSqM,
        // panels: Math.floor(area / CONFIG.solarPanelArea),
      }]
    });
  }

  deleteRoof = (index) => {
    const { roofList, } = this.state;
    roofList.splice(index, 1);
    this.setState({ roofList });
  }

  _handlenoOfResidentsChange=  (e) => {
    this.setState({ noOfResidents: e.target.value });
  }
  _handlewaterStorageCapacityChange = (e) =>{
    this.setState({ waterStorageCapacity: e.target.value });
  }
  getAnalysis = event => {
    event.preventDefault();
    const user = {
      name: this.state.name
    };
    const body = {
      address_components: this.state.address_components,
      formatted_address: this.state.formatted_address,
      lat: this.state.lat,
      lng: this.state.lng,
      place_id: this.state.place_id,
      roofList: this.state.roofList,
      noOfResidents:this.state.noOfResidents,
      waterStorageCapacity:this.state.waterStorageCapacity
    }
    axios.post(`https://yrtpxbkelc.execute-api.us-east-1.amazonaws.com/default/getAnalysis`, JSON.stringify(body))
      .then(res => {
        console.log(res.data)
        this.setState({raindata: res.data});
      })
  }

  render() {
    const { lat, lng, roofList, } = this.state, addr = this.state.address_components, { classes } = this.props;
    return (
      <React.Fragment>
        <AppBar />
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Paper className={classes.map}>
                <DrawableMap onPolygonComplete={this.addRoof} defaultCoords={{ lat, lng }}></DrawableMap>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.location}>
                {'Location: '}
                {addr && (<>
                  {(addr[0]) && addr[0].long_name}
                  {(addr[1]) && `, ${addr[1].long_name}`}
                  {(addr[2]) && `, ${addr[2].long_name}`}
                  {(addr[3]) && `, ${addr[3].long_name}`}
                </>)}

              </Paper>
              <div className={classes.scroll}>
                {roofList.length ? <>
                  {roofList.map((roof, index) => {
                    const { lat, lng, area, isOpenAdvOptions, } = roof;
                    return (
                      <Paper key={index} className={classes.list}>
                        <h3>
                          Roof {index + 1}
                          <span style={{ float: "right" }} title="Remove this area">
                            <Close style={{ cursor: "pointer" }} onClick={() => this.deleteRoof(index)} />
                          </span>
                        </h3>

                        <Grid container spacing={3}>
                          <Grid item xs>Area: &emsp; {area.toFixed(2)}</Grid>
                          <Grid item xs>
                            Coordinates: &emsp; {`${Math.abs(lat).toFixed(3)}° ${(lat > 0) ? 'N' : 'S'}, ${Math.abs(lng).toFixed(3)}° ${(lng > 0) ? 'E' : 'W'}`}
                          </Grid>
                        </Grid>

                        <Grid item xs style={{ cursor: "pointer", margintTop: "0.5em" }} onClick={() => (roof.isOpenAdvOptions = !isOpenAdvOptions)}>
                          <em>Advanced Options...</em>
                          <ArrowForwardIosIcon style={{ ...DropdownTransform, transform: `rotate(${isOpenAdvOptions ? '90' : '0'}deg)` }} />
                        </Grid>
                      </Paper>
                    );
                  })}
                </> :
                  <Paper className={classes.map}>
                    <em>Please choose roof(s) from the adjacent map.</em>
                  </Paper>}
              </div>
              {(roofList.length !== 0) && (
                <div className={classes.customInput}>
                  <TextField
                    className={classes.customInputField}
                    id="noOfResidents"
                    value={this.state.noOfResidents}
                    onChange={this._handlenoOfResidentsChange}
                    label="No. of residents:"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    className={classes.customInputField}
                    id="waterStorageCapacity"
                    value={this.state.waterStorageCapacity}
                    onChange={this._handlewaterStorageCapacityChange}
                    label="Water storage capacity (lit.)"
                    type="number"
                    step="500"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <Button className={classes.analyzeBtn} onClick={this.getAnalysis}>Analyze</Button>
                </div>
              )}
            </Grid>

          </Grid>
        </div>
        { (this.state.raindata.length != 0) &&
          <div className={classes.root}>
            <Grid container spacing={12}>
              <Grid item xs={12}>
                <Saving raindata={this.state.raindata} />
              </Grid>
            </Grid>
          </div>
  }
        <AppFooter />
      </React.Fragment>
    )
  };
}

export default withRoot(withStyles(useStyles)(MapResults));


