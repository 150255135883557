import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Loadable from 'react-loadable';
import {LoadingComponent} from './lib/components/loadingComponent';


const AppAsync = Loadable({
  loader:()=>import('./App'),
  loading:LoadingComponent
})

ReactDOM.render(<AppAsync />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();


