import React from 'react';
import './App.css';
import { Router } from "@reach/router";
import Home from "./home/homeComponent";
import MapResults from "./map-result/MapResults";

function App() {
  return (
    <Router>
      <Home path="/" />
      <MapResults path="/selectarea" />
    </Router>
  );
}

export default App;
